@import '../variables.scss';

.footer {
    font-family: "Unbounded", sans-serif;

    .container {
        margin: 0 auto;
        padding: 10px;
        max-width: $content-width;
    }

    .logo {
        margin: 10px 0;
        text-align: right;
        
        svg {
            height: 50px;
            width: auto;
        }
    }
}