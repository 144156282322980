@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;700&family=Unbounded:wght@300&display=swap');

body {
  margin: 0;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, 'Roboto', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  height: 100%;
  margin: 0;
  padding: 0;

  background-color: #121212;
  color: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4 {
  font-family: "Unbounded", sans-serif;
  color: #ffffff;
}

a {
  color: inherit;
  text-decoration: underline dotted 1px;
}

::-webkit-scrollbar {
  width: 12px;
}
::-webkit-scrollbar-track {
  background: #121212;
}
::-webkit-scrollbar-thumb {
  background: #2a2a2a;
}
::-webkit-scrollbar-thumb:hover {
  background: #404040;
}

.hover {
  fill: #ffffff;
}

.test-hit {
  fill: #ffff00;
}