@import '../variables.scss';

.blocks {
    margin: 0 auto;

    .stylist {
        background-color: $stylist-bg;
        color: $stylist-fg;
    }
    .model {
        background-color: $model-bg;
        color: $model-fg;
    }
    .ambassador {
        background-color: $ambassador-bg;
        color: $ambassador-fg;
    }
}

.container {
    margin: 0 auto;
    padding: 0 10px 30px 10px;
    max-width: $content-width;

    h2 {
        font-size: 60px;
        margin: 0;
        text-wrap: nowrap;
        transform: translate(0, -30px);
        margin-bottom: -20px;
        white-space: nowrap;
        // text-wrap: nowrap;

        @media (max-width: $mobile-breakpoint) {
            font-size: 26px;
            transform: translate(0, -13px);
            margin-bottom: -10px;
        }
    }

    h4 {
        font-family: "Unbounded", sans-serif;
        margin: 0 0 10pt 0;
        font-size: 16px;
    }

    p {
        margin: 3pt 0;
        font-size: 22px;
    }

    .cards {
        display: flex;
        gap: 10pt;
        margin: 10pt 0;
        flex-direction: row;
        flex-flow: row wrap;
        @media (max-width: $mobile-breakpoint) {
            flex-direction: column;
            margin: 10pt;
        }

        .card {
            flex: 1;
            display: flex;
            flex-direction: column;

            > * {
                flex: 1;
            }
            h4 {
                flex: 0;
            }
            .actions {
                flex: 0;
            }

            .description {
                font-size: 18px;
                // text-align: justify;
            }
        }
    }

    .columns {
        display: flex;
        flex-direction: row;
        gap: 20pt;
        @media (max-width: $mobile-breakpoint) {
            flex-direction: column;
            margin: 10pt;
        }
        > div {
            flex: 1;
        }

        .text {
            flex: 3;
        }
        .photos {
            flex: 4;
        }
    }

    .photos {
        position: relative;
        height: 80vh;
        overflow: hidden;
        border-radius: 4px;

        .photosContainer {
            height: 80vh;
            text-wrap: nowrap;
            position: relative;
            text-wrap: nowrap;
        }

        .photosOverlay {
            position: absolute;
            left: 0;
            top: 0;
            z-index: 2;
            
            padding: 20px;
            width: 50%;
            @media (max-width: $mobile-breakpoint) {
                width: auto;
            }
            
            p {
                color: #ffffff;
                text-shadow: 1px 1px 2px #000000;
            }
        }

        img {
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            position: absolute;
            object-position: center;
            object-fit: cover;
        }
    }
}

.button {
    border-radius: 4px;
    padding: 4pt 8pt;
    margin: 4pt 0;
    text-decoration: none;
    /* color: #ffffff; */
    display: inline-block;
    font-size: 14px;
    font-family: "Unbounded", sans-serif;

    // background-color: #ed4ad6;
    // color: #8b0077;
    background-color: rgba(255,255,255,0.3);
    color: rgba(0,0,0,0.8);
    transition: all 200ms ease-in;
}

.button:hover {
    background-color: rgba(0,0,0,0.3);
    color: rgba(255,255,255,0.9);
    transition: all 200ms ease-in;
}

.social {
    // height: 32px;
    // width: auto;
    // fill: $ambassador-fg;
    display: block;
    
    a {
        font-family: "Unbounded", sans-serif;
        text-decoration: underline dotted 1px;
        color: $stylist-light;
    }

    svg {
        vertical-align: middle;
        padding-bottom: 3px;
        padding-right: 6pt;
        height: 24px;
        width: 24px;
        fill: $stylist-light;
        stroke: $stylist-light;
    }
}
