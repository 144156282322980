@import '../../variables.scss';

.popup {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 10pt;
    padding: 10pt;
    border: 1px solid #ffffff20;
    border-radius: 5px;
    background-color: $global-bg;
    overflow: auto;
}

.popup::-webkit-scrollbar {
    width: 8px;
}
.popup::-webkit-scrollbar-track {
    background: $global-bg;
}
.popup::-webkit-scrollbar-thumb {
    background: #434343;
    border-radius: 3px;
}
.popup::-webkit-scrollbar-thumb:hover {
    background: #b0b0b0;
}

.popup .close {
    border: none;
    background: none;
    font-size: 30px;
    color: #ffffff;
    position: fixed;
    top: 20px;
    width: 30px;
    height: 30px;
    right: 30px;
    margin: 0;
    padding: 0;
    text-shadow: 1px 1px $global-bg;
    cursor: pointer;
}

.popup-container .page {
    display: none;
}

.popup-container.visible, .popup-container .page.visible {
    display: block;
}
