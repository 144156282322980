@import '../variables.scss';

.header {
    padding: 40px 0;
    text-align: center;
    font-family: "Unbounded", sans-serif;
    background-image: url(../icons/savadogo-header.svg);
    background-position: center;
    background-size: contain;
    // background-repeat: no-repeat;

    h1 {
        margin: 0;
        font-size: 55px;
        @media (max-width: $mobile-breakpoint) {
            font-size: 30px;
        }
    }

    .links {
        font-size: 20px;
        @media (max-width: $mobile-breakpoint) {
            font-size: 11px;
        }

        a {
            display: inline-block;
            margin: 0 3pt;
            text-decoration: underline dotted 1px;
        }

        .stylist {
            color: $stylist-light;
        }
        .model {
            color: $model-bg;
        }
        .ambassador {
            color: $ambassador-bg;
        }
    }
}

