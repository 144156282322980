$mobile-breakpoint: 600px;
$content-width: 1000px;

$global-bg: #121212;

$stylist-bg:#c318ab;
$stylist-light: #ff73eb;
$stylist-fg: #ffffff;

$model-bg: #c2ef15;
$model-fg: #062c09;

$ambassador-bg: #58fbfa;
$ambassador-fg: #081575;